import React, { Fragment, useEffect, useState } from "react"
import moment from "moment"
import classNames from "classnames"
import axios from "axios"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

import { isBrowser } from "services/general"
import { getAnnouncements } from "./services/getAnnouncements"

const Notification = () => {
  let dateToday = moment().format("YYYY-MM-DD hh:mm:ss")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    const getBanner = async () => {
      const params = {
        website: process.env.GATSBY_GITHUB_REPO,
        env: process.env.GATSBY_ENV,
      }

      const response = await axios.get(
        process.env.GATSBY_AIRTABLE_BANNER_ENDPOINT,
        { params }
      )

      let actualBannerContent = response?.data?.content || null

      setBanner(actualBannerContent)
    }

    getBanner()
  }, [])

  let notificationMessage = null

  let isOrderingAsGuest = false
  if (process.env.GATSBY_ORDER_AS_GUEST) {
    if (isBrowser()) {
      isOrderingAsGuest =
        window.sessionStorage.getItem(process.env.GATSBY_ORDER_AS_GUEST_KEY) ===
        process.env.GATSBY_ORDER_AS_GUEST_VALUE
          ? true
          : false
    }
  }

  let notificationBlurb =
    "For any inquiries, send us a message with a description of your concern through careplus@medgrocer.com or "
  if (isOrderingAsGuest) {
    notificationBlurb = `The Order as Guest page will only be available until ${process.env.GATSBY_AUTH_NOTIFICATION}. If you experience trouble signing in, please text or call us at `
  }

  if (!!banner)
    notificationMessage = (
      <Fragment>
        <span
          className="helper is-size-5"
          dangerouslySetInnerHTML={{
            __html: banner
              ?.map((elem) => elem?.replace("<p>", "<p class='is-size-6'>"))
              ?.join(""),
          }}
        />
      </Fragment>
    )

  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
